import Link from 'next/link';
import Head from 'next/head';

const Custom404 = () => {
  return (
    <main><Head>
    <title>404 </title>
    <meta name="description" content="Buy cheap data bundles, airtime online for MTN, Glo, Airtel, and 9mobile, cable tv subscriptions, electricity bills payment, education pins. Swiftly process automated services from all service providers." />
    <meta name="keywords" content="cheap-data-bundles, buy-data, airtime, top-up, MTN, Glo, Airtel, 9mobile, etisalat, VTU, cable-tv, dstv, gotv, startimes, showmax, electricity-paymenets, prepaid-meter, postpaid-meter, ikedc, ekedc, aedc, kedco, phed, jed, kaedco, eedc, ibedc, bedc, education-payments, exam-pins, neco, waec, jamb, utme, nabteb" />
  </Head>
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
      <p className="text-xl text-gray-700 mb-8">Sorry, the page you&apos;re looking for does not exist.</p>
      <Link href="/"
         className="text-primary hover:underline">Go back to home
      </Link>
    </div>
    </main>
  );
};

export default Custom404;
